<template>
	<div>
		<div style="text-align:center;background-color:#4b80c3;padding:10px;">
			<h1 style="color:#f1f1f1;letter-spacing: 4px;">{{ this.$route.query.nav === "新闻资讯" ? "新闻资讯-详情" : "通知公告-详情" }}</h1>
		</div>
		<div style="max-width:1200px;margin:auto;text-align: center;margin-top:10px;margin-bottom:10px;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/list_article?nav=' + article.category.label }">
					{{ article.category.label }}
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{ article.title }}</el-breadcrumb-item>
			</el-breadcrumb>
			<h3>{{ article.title }}</h3>
			<span style="font-size: smaller;color: #787878;">发布时间：{{ article.release_time }}</span>
			<div style="padding:20px 0px;text-align: left;" v-html="article.text"></div>
		</div>
	</div>
</template>

<script>
import { apiGetArticleById } from "@/api/pub/article.js";
export default {
	data() {
		return {
			type: "news",
			article: {
				title: "",
				category: {
					label: ""
				}
			}
		};
	},
	methods: {
		// 获取后台菜单
		async getArticle() {
			try {
				//console.log(this.$route.query.menu_id);
				const response = await apiGetArticleById(this.$route.query.id);
				//console.log(response);
				if (response.data.code == 200) {
					this.article = response.data.data;
					//console.log(this.article);
					//this.$refs.content.innerHTML = this.article.content;
				} else {
					console.log(response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		updateActive() {
			//console.log(this.$parent.$parent);
			this.$parent.$parent.$parent.updateActiveIndex();
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.getArticle();
		this.updateActive();
	}
};
</script>

<style lang="scss" scoped></style>
